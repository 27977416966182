import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from "../../services/data.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-quote",
  templateUrl: "./quote.component.html",
  styleUrls: ["./quote.component.css"],
})
export class QuoteComponent implements OnInit {
  waitSend = false;
  searchForm: FormGroup;
  simulateForm: FormGroup;
  searchResults: any;
  simulationResults: any;
  dataTable: any;
  typeProduct;
  displayedColumns: Array<string> = ["term", "financingQuote", "newQuote"];
  totalAvailableCredit: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private _snackBar: MatSnackBar
  ) {
    this.searchForm = this.formBuilder.group({
      Documento: [
        "",
        [
          Validators.required,
          Validators.minLength(13),
          Validators.maxLength(14),
        ],
      ],
    });

    this.simulateForm = this.formBuilder.group({
      ValorSimular: ["", [Validators.required]],
      IdTipoProducto: ["", [Validators.required]],
    });

    this.searchCatalogue();
  }

  ngOnInit() { }

  searchCatalogue() {
    this.dataService.catalogos().subscribe((data) => {
      if (data.IdError == 0) {
        for (let e in data.Lista) {
          if (data.Lista[e].TipoCatalogo == "TipoProducto") {
            this.typeProduct = data.Lista[e].Catalago;
          }
        }
      }
      console.log(this.typeProduct);
    });
  }

  onKeyDoc(event: any) {
    let format =
      /[ `!¡@#$%^&*()'¿_+\-=\[\]{};:"\\|,.<>\/?~AaBbCcDdEeFfGgHhIiJjKkLlMmNnÑñOoPpQqRrSsTtUuVvWwXxY ]/;
    let num = event.target.value;
    if (format.test(num)) {
      let id = num.indexOf(event.key);
      num = num.slice(0, id);
      this.searchForm.controls["Documento"].setValue(num);
    }
  }
  search() {
    this.waitSend = true;

    const formData = {
      IdTipoDocumento: 1,
      ...this.searchForm.value,
    };

    this.dataService.getQuote(formData).subscribe((response) => {
      this.waitSend = false;

      if (response.Respuesta.IdError === 0) {
        this.searchResults = response;
        
      } else {
        this.searchResults = false;
        this.openSnackBar(response.Respuesta.Mensaje, "Cerrar");
      }
    });
  }

  simulate() {
    this.waitSend = true;

    const formData = {
      IdTipoDocumento: 1,
      ...this.searchForm.value,
      ...this.simulateForm.value,
    };

    this.dataService.getEmulation(formData).subscribe((response) => {
      this.waitSend = false;

      if (response.Respuesta.IdError === 0) {
        this.simulationResults = response.RespuestaSimulaciones;
        this.dataTable = this.simulationResults;
      } else {
        this.openSnackBar(response.Respuesta.Mensaje, "Cerrar");
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000,
    });
  }

  reset() {
    this.searchResults = null;
    this.simulationResults = null;
    this.dataTable = null;
    this.searchForm.reset();
    this.simulateForm.reset();
  }
}
